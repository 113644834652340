import { z } from 'zod';
import type { SchemaErrors } from './SchemaErrors';

export const owner = z.object({
  id: z.number().positive(),
  fullName: z.string().nonempty(),
  userId: z.string().nonempty().optional(),
  officeId: z.number().positive().optional(),
});

export type OwnerSchema = z.infer<typeof owner>;
export type OwnerValidationErrors = SchemaErrors<OwnerSchema>;
