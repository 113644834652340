import { z } from 'zod';
import { SchemaErrors } from './SchemaErrors';

export const confidenceInterval = z.object({
  id: z.number(),
  confidencePercentage: z.string(),
  confidenceValue: z.union([z.string(), z.number()]).optional(),
});

export type ConfidenceIntervalSchema = z.infer<typeof confidenceInterval>;
export type ConfidenceIntervalValidationErrors = SchemaErrors<ConfidenceIntervalSchema>;
