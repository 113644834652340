import { z } from 'zod';

const addressSchema = z.object({
  id: z.number().positive(),
  addressLine1: z.string().nonempty(),
  addressLine2: z.string().nonempty(),
  addressLine3: z.string().nonempty(),
  city: z.string().nonempty(),
  locality: z.string().nonempty(),
  region: z.string().nonempty(),
  country: z.string().nonempty(),
  postalCode: z.string().nonempty(),
  timeZone: z.string().nonempty(),
  fullAddress: z.string().nonempty(),
});

export type AddressSchema = z.infer<typeof addressSchema>;

const emailSchema = z.object({
  id: z.number().positive().optional(),
  email: z.string().nonempty({ message: 'Email must contain at least one character(s)' }),
});

export type EmailSchema = z.infer<typeof emailSchema>;

const phoneNumberSchema = z.object({
  id: z.number().positive().optional(),
  addressType: z.string().nonempty(),
  number: z.string().nonempty({ message: 'Phone Number must contain at least one character(s)' }),
});

export type PhoneNumberSchema = z.infer<typeof phoneNumberSchema>;

const webPageSchema = z.object({
  id: z.number().positive().optional(),
  addressType: z.string().nonempty(),
  url: z.string().nonempty({ message: 'Website must contain at least one character(s)' }),
});

export type WebPageSchema = z.infer<typeof webPageSchema>;

const addressableDetailSchema = z.object({
  addresses: z.array(addressSchema),
  phoneNumbers: z.array(phoneNumberSchema),
  webPages: z.array(webPageSchema),
});

export type AddressableDetailSchema = z.infer<typeof addressableDetailSchema>;

export type AddressableDetailErrors = {
  [K in keyof AddressableDetailSchema]?: string;
}

export const useAddressableDetailSchema = () => ({
  addressSchema,
  addressableDetailSchema,
  emailSchema,
  phoneNumberSchema,
  webPageSchema,
});
