export const button = {
  colorScheme: {
    light: {
      root: {
        primary: {
          background: '{emerald.500}',
          hoverBackground: '{emerald.600}',
          activeBackground: '{emerald.700}',
          borderColor: '{emerald.500}',
          hoverBorderColor: '{emerald.600}',
          activeBorderColor: '{emerald.700}',
          focusRing: {
            color: '{emerald.500}',
          },
        },
      },
    },
  },
};
