export interface LocalStorageItem<T> {
  key: string;
  value: T;
}

export const useLocalStorage = () => {
  const setItem = <T>(item: LocalStorageItem<T>): void => window.localStorage.setItem(item.key, JSON.stringify(item.value, mapReplacer));
  const getItem = <T>(key: string): T => {
    const json = window.localStorage.getItem(key);

    if (!json) {
      return null;
    }

    try {
      return JSON.parse(json, mapReviver) as T;
    } catch {
      console.error(`Invalid JSON for localStorage key ${key}`);
      return null;
    }
  };

  const removeItem = (key: string) => window.localStorage.removeItem(key);

  const mapReplacer = (key: string, value: any) => {
    if (value instanceof Map) {
      return {
        dataType: 'Map',
        value: [...value.entries()],
      };
    }

    return value;
  };

  const mapReviver = (_: string, value: any) => {
    if (typeof value === 'object' && value !== null) {
      if (value.dataType === 'Map') {
        return new Map(value.value);
      }
    }

    return value;
  };

  return {
    getItem,
    removeItem,
    setItem,
  };
};
