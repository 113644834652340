import { z } from 'zod';
import { useOpportunityStore } from '@apparatix/stores/useOpportunityStore.ts';
import type { OpportunityType } from '@apparatix/types/Leads.ts';
import { OwnerSchema, useOwnerDetailSchema } from './useOwnerDetailSchema.ts';

const { ownerSchema } = useOwnerDetailSchema();

const temperatureSchema = z.object({
  id: z.number().positive(),
  temperature: z.string(),
  color: z.string(),
});

export type TemperatureSchema = z.infer<typeof temperatureSchema>;

const statusSchema = z.object({
  id: z.number().positive(),
  order: z.string().refine(val => {
    if (val === '' || val === undefined || val === null) return true;
    const num = typeof val === 'number' || typeof val === 'string' ? Number(val) : NaN;
    if (isNaN(num) || num < 0) {
      return false;
    }
    return true;
  }, { message: 'Order must be a positive number' }),
  status: z.string(), // TODO: Add possible options
  state: z.string(), // ^^^
});

export type StatusSchema = z.infer<typeof statusSchema>;

const leadSchema = z.object({
  name: z.string().optional(),
  owner: ownerSchema,
  status: statusSchema,
  temperature: temperatureSchema,
});

export type LeadDetailSchema = z.infer<typeof leadSchema>;

export type LeadDetailErrors = {
  [K in keyof LeadDetailSchema]?: string;
}

export const useOpportunityDetailSchema = () => {
  const store = useOpportunityStore();

  const getDefaultOwner = (type: OpportunityType): OwnerSchema => {
    if (!store.currentUser) return undefined;

    return store.options[type]?.users?.find(user => user.id === store.currentUser.id);
  };

  const getDefaultStatus = (type: OpportunityType): StatusSchema => store.options[type]?.availableStatuses[0];

  const getDefaultTemperature = (type: OpportunityType): TemperatureSchema => store.options[type]?.temperatures[0] as TemperatureSchema;

  return {
    getDefaultOwner,
    getDefaultStatus,
    getDefaultTemperature,
    leadSchema,
  };
};
