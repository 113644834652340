import { z } from 'zod';
import { SchemaErrors } from './SchemaErrors';

export const campaignInterval = z.object({
  id: z.number(),
  selection: z.string(),
  selectionValue: z.string(),
});

export type CampaignIntervalSchema = z.infer<typeof campaignInterval>;
export type CampaignIntervalValidationErrors = SchemaErrors<CampaignIntervalSchema>;
