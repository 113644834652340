import { computed, ref, watch } from 'vue';
import { defineStore } from 'pinia';
import {
  type AccountDetail, type RevenueGraphItem, type BoardLocation, type PhoneNumber, type Options, type Overview, type GetAccountAssociationResponse, AccountOverview, AeLimits, User,
} from '@apparatix/types/Account';
import { Activity } from '@apparatix/types/Activities';
import { type AssociationContact } from '@apparatix/types/Leads';
import type { DataTableFilterMeta } from 'primevue/datatable';
import { FilterMatchMode } from '@primevue/core/api';

const defaultFilters: DataTableFilterMeta = {
  c2Id: { value: null, matchMode: FilterMatchMode.CONTAINS },
  c2Name: { value: null, matchMode: FilterMatchMode.CONTAINS },
  c2Type: { value: null, matchMode: FilterMatchMode.IN },
  agencyName: { value: null, matchMode: FilterMatchMode.CONTAINS },
  address: { value: null, matchMode: FilterMatchMode.CONTAINS },
  ownerName: { value: null, matchMode: FilterMatchMode.IN },
  primaryContact: { value: null, matchMode: FilterMatchMode.CONTAINS },
  c2Sic: { value: null, matchMode: FilterMatchMode.IN },
  markets: { value: null, matchMode: FilterMatchMode.CONTAINS },
};

export const useAccountStore = defineStore('account-store', () => {
  const accounts = ref<AccountOverview[]>([]);
  const aeLimit = ref<AeLimits>();
  const users = ref<User[]>();
  const paymentOptions = ref();

  const canViewAELimits = ref(false);
  const canAddAccounts = ref(false);
  const canChangeOwner = ref(false);
  const canOwnAgencies = ref(false);
  const invoiceFieldsLocked = ref(true);
  const isLoading = ref(false);

  const accountDetails = ref<AccountDetail>();
  const accountOverview = ref<Overview>();
  const accountAssociations = ref<GetAccountAssociationResponse>();
  const accountActivities = ref<Activity[]>();
  const options = ref<Options>();
  const selectedAccount = ref<AccountOverview>();

  const revenueGraph = ref<RevenueGraphItem[]>();
  const currentLocations = ref<BoardLocation[]>();
  const futureLocations = ref<BoardLocation[]>();

  const tableFilters = ref<DataTableFilterMeta>({ ...defaultFilters });
  const previousTableFilters = ref<DataTableFilterMeta>();
  const showFilters = ref(true);
  const filteredAccountLength = ref<number>();

  const workPhone = ref<PhoneNumber>({ addressType: 'Work', number: '' });
  const cellPhone = ref<PhoneNumber>({ addressType: 'Cell', number: '' });
  const faxPhone = ref<PhoneNumber>({ addressType: 'Fax', number: '' });

  const webPage = ref({ addressType: 'Work', url: '' });

  const address = ref({
    addressLine1: '',
    addressLine2: '',
    city: '',
    region: '',
    postalCode: '',
    fullAddress: '',
  });

  // Computed function that parses the user given url into one that can be used for a link
  const computedUrl = computed(() => {
    let url = webPage.value.url.trim();

    // Add 'https://' if missing
    if (!(/^https?:\/\//i).test(url)) {
      url = 'https://' + url;
    }

    return url;
  });

  const selectedTags = ref([]);

  const filteredAccounts = computed<AccountOverview[]>(() => {
    if (selectedTags.value) {
      return accounts.value.filter(row => selectedTags.value.every(tag => row.accountOptions.map(option => option.value).includes(tag.value)));
    }

    return accounts.value;
  });

  const resetTableFilters = () => {
    selectedTags.value = [];
    tableFilters.value = { ...defaultFilters };
  };

  const isFilterChanged = computed<boolean>(() => JSON.stringify(tableFilters.value) !== JSON.stringify(defaultFilters) || selectedTags.value.length !== 0);

  const hideTableFilters = () => {
    const activeFilter = JSON.stringify(tableFilters.value);
    const defaultFilter = JSON.stringify(defaultFilters);

    if (activeFilter !== defaultFilter) {
      previousTableFilters.value = { ...tableFilters.value };
    }
    tableFilters.value = undefined;
    selectedTags.value = [];

  };

  const filterByName = (name: string) => {
    const nameFilter = { value: name, matchMode: FilterMatchMode.EQUALS };
    const newFilters: DataTableFilterMeta = {
      ...defaultFilters,
      c2Name: nameFilter,
    };
    tableFilters.value = newFilters;
  };

  const showTableFilters = () => {
    if (previousTableFilters.value) {
      tableFilters.value = { ...previousTableFilters.value };
      previousTableFilters.value = undefined;
    } else {
      resetTableFilters();
    }
  };

  const initializePhones = () => {
    if (!accountDetails.value) return;

    const phoneTypes = ['Work', 'Cell', 'Fax'];

    phoneTypes.forEach((type) => {
      let phone = accountDetails.value.phoneNumbers?.find(
        (phoneNumber) => phoneNumber.addressType === type,
      );

      if (!phone) {
        phone = {
          addressType: type,
          number: '',
        };
      }

      if (type === 'Work') {
        workPhone.value = phone;
      } else if (type === 'Cell') {
        cellPhone.value = phone;
      } else if (type === 'Fax') {
        faxPhone.value = phone;
      }

    });
  };

  const initializeWebPages = () => {
    if (!accountDetails.value) return;

    const urlTypes = ['Work'];

    urlTypes.forEach((type) => {
      let url = accountDetails.value.webPages?.find(
        (urlPage) => urlPage.addressType === type,
      );

      if (!url) {
        url = {
          addressType: type,
          url: '',
        };
      }

      if (type === 'Work') {
        webPage.value = url;
      }

    });
  };

  const initializeAddress = () => {
    if (!accountDetails.value) return;

    let addressLine = accountDetails.value.addresses[0];

    if (!addressLine) {
      addressLine = {
        addressLine1: '',
        addressLine2: '',
        city: '',
        region: '',
        postalCode: '',
        fullAddress: '',
      };
    }

    address.value = addressLine;
  };

  const addContact = (contact: AssociationContact) => {
    const idx = accountAssociations.value.contacts.findIndex(ct => ct.id === contact.id);
    if (idx === -1) {
      accountAssociations.value.contacts.push(contact);
    } else {
      accountAssociations.value.contacts[idx] = contact;
    }
  };

  const removeContact = (contact: AssociationContact) => {
    const idx = accountAssociations.value.contacts?.findIndex(ct => ct.id === contact.id);
    if (idx === -1) return;
    accountAssociations.value.contacts.splice(idx, 1);
  };

  // Whenever we get an update to account details, refresh the split out variables in the store.
  watch(
    () => accountDetails.value,
    (newVal) => {
      if (newVal) {
        initializePhones();
        initializeWebPages();
        initializeAddress();
      }
    },
  );


  return {
    accountActivities,
    accountAssociations,
    accountDetails,
    accountOverview,
    accounts,
    addContact,
    address,
    aeLimit,
    canAddAccounts,
    canChangeOwner,
    canOwnAgencies,
    canViewAELimits,
    cellPhone,
    computedUrl,
    currentLocations,
    faxPhone,
    filteredAccountLength,
    filteredAccounts,
    filterByName,
    futureLocations,
    hideTableFilters,
    invoiceFieldsLocked,
    isFilterChanged,
    isLoading,
    options,
    paymentOptions,
    removeContact,
    resetTableFilters,
    revenueGraph,
    selectedAccount,
    selectedTags,
    showFilters,
    showTableFilters,
    tableFilters,
    users,
    webPage,
    workPhone,
  };
});
