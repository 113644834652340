import { z } from 'zod';
import { useAddressableDetailSchema } from './useAddressableDetailSchema.ts';
import { useOwnerDetailSchema } from './useOwnerDetailSchema.ts';

const { addressSchema, phoneNumberSchema, webPageSchema } = useAddressableDetailSchema();
const { ownerSchema } = useOwnerDetailSchema();

const industrySchema = z.object({
  code: z.string().nonempty(),
  description: z.string().nonempty(),
});

export type IndustrySchema = z.infer<typeof industrySchema>;

const sourceSchema = z.object({
  selection: z.string().nonempty(),
});

export type SourceSchema = z.infer<typeof sourceSchema>;

const marketSchema = z.object({
  id: z.number().positive(),
  market: z.string().nonempty(),
});

export type MarketSchema = z.infer<typeof marketSchema>;

const accountDetailSchema = z.object({
  id: z.number().positive(),
  owner: ownerSchema,
  name: z.string().optional(),
  legalName: z.string().nonempty({ message: 'Legal Name required' }),
  industry: industrySchema,
  markets: z.array(marketSchema),
  source: sourceSchema,
  addresses: z.array(addressSchema),
  phoneNumbers: z.array(phoneNumberSchema),
  webPages: z.array(webPageSchema),
});

export type AccountDetailSchema = z.infer<typeof accountDetailSchema>;

export type AccountDetailErrors = {
  [K in keyof AccountDetailSchema]?: string;
}

export const useAccountDetailSchema = () =>
  ({
    accountDetailSchema,
  })
;
