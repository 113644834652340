import { z } from 'zod';

const ownerSchema = z.object({
  id: z.number().positive(),
  fullName: z.string().nonempty(),
  userId: z.string().nonempty(),
  officeId: z.number().positive(),
});

export type OwnerSchema = z.infer<typeof ownerSchema>;

export const useOwnerDetailSchema = () => ({
  ownerSchema,
});
