import { z } from 'zod';
import type { SchemaErrors } from './SchemaErrors';
import { leadDetail } from './LeadDetail';
import { confidenceInterval } from './ConfidenceInterval';
import { campaignInterval } from './CampaignInterval';
import { office } from './Office';
import { optionalNumber } from './OptionalNumber';


const opportunitySchema = z.object({
  anticipatedStart: z.date().optional(),
  dueDate: z.date().optional(),
  office: office.optional(),
  confidenceInterval: confidenceInterval.optional(),
  campaignInterval: campaignInterval.optional(),
  budget: optionalNumber(),
  rate: optionalNumber(),
  periods: optionalNumber(),
  opportunityLostReasons: z.array(z.number().or(z.string())),
});

export const opportunityDetail = leadDetail.merge(opportunitySchema);

export type OpportunityDetailSchema = z.infer<typeof opportunityDetail>;
export type OpportunityDetailValidationErrors = SchemaErrors<OpportunityDetailSchema>;
