import {
  computed, reactive, ref, onMounted, onUnmounted,
} from 'vue';
import { defineStore } from 'pinia';
import type {
  AssociationAccount,
  AssociationContact,
  DataAccessibleByType,
  DetailEditorContext,
  FilterStoreState,
  Office,
  OpportunityBase,
  OpportunityDetail,
  OpportunityFilter,
  OpportunityType,
  Options,
  OptionsUser,
  PayloadBase,
  Swimlane,
  User,
  ViewFormat,
} from '@apparatix/types/Leads';
import type { CreateAccountResponse } from '@apparatix/types/Account';
import { useEndpoint } from '@apparatix/composables';
import { Activity } from '@apparatix/types/Activities';
import type { DataTableFilterMeta } from 'primevue/datatable';
import { FilterMatchMode } from '@primevue/core/api';
import { useLocalStorage } from '@apparatix/composables';
import type { Column } from '@apparatix/types';

const leadDefaultFilters: DataTableFilterMeta = {
  id: { value: null, matchMode: FilterMatchMode.CONTAINS },
  name: { value: null, matchMode: FilterMatchMode.CONTAINS },
  company: { value: null, matchMode: FilterMatchMode.CONTAINS },
  accountMarkets: { value: null, matchMode: FilterMatchMode.CONTAINS },
  owner: { value: null, matchMode: FilterMatchMode.IN },
  status: { value: null, matchMode: FilterMatchMode.IN },
  temperature: { value: null, matchMode: FilterMatchMode.IN },
  primaryContact: { value: null, matchMode: FilterMatchMode.CONTAINS },
  valueFormatted: { value: null, matchMode: FilterMatchMode.CONTAINS },
  note: { value: null, matchMode: FilterMatchMode.CONTAINS },
};

const opportunityDefaultFilters: DataTableFilterMeta = {
  'id': { value: null, matchMode: FilterMatchMode.CONTAINS },
  'name': { value: null, matchMode: FilterMatchMode.CONTAINS },
  'company': { value: null, matchMode: FilterMatchMode.CONTAINS },
  'accountMarkets': { value: null, matchMode: FilterMatchMode.CONTAINS },
  'owner': { value: null, matchMode: FilterMatchMode.IN },
  'status': { value: null, matchMode: FilterMatchMode.IN },
  'ci': { value: null, matchMode: FilterMatchMode.IN },
  'temperature': { value: null, matchMode: FilterMatchMode.IN },
  'primaryContact': { value: null, matchMode: FilterMatchMode.CONTAINS },
  'office.office': { value: null, matchMode: FilterMatchMode.IN },
  'anticipatedStart': { value: null, matchMode: FilterMatchMode.CONTAINS },
  'dueDate': { value: null, matchMode: FilterMatchMode.CONTAINS },
  'rateFormatted': { value: null, matchMode: FilterMatchMode.CONTAINS },
  'interval': { value: null, matchMode: FilterMatchMode.IN },
  'periods': { value: null, matchMode: FilterMatchMode.CONTAINS },
};

export const useOpportunityStore = defineStore('opportunity-store', () => {
  const swimlanes = ref<DataAccessibleByType<Swimlane[]>>({
    Opportunity: [],
    Lead: [],
  });
  const options = ref<DataAccessibleByType<Options>>({
    Opportunity: null,
    Lead: null,
  });
  const stateReady = ref<DataAccessibleByType<boolean>>({
    Opportunity: false,
    Lead: false,
  });
  const currentState = ref<DataAccessibleByType<string>>({
    Opportunity: 'Active',
    Lead: 'Active',
  });
  const currentView = ref<OpportunityType>();
  const viewFormat = ref<DataAccessibleByType<ViewFormat>>({
    Opportunity: 'swimlanes',
    Lead: 'swimlanes',
  });
  const shouldShowDetail = ref<DataAccessibleByType<boolean>>({
    Opportunity: false,
    Lead: false,
  });
  const selectedOpportunity = ref<DataAccessibleByType<OpportunityDetail | null>>({
    Opportunity: null,
    Lead: null,
  });
  const selectedCampaignId = ref<DataAccessibleByType<number | null>>({
    Opportunity: null,
    Lead: null,
  });
  const opportunityActivities = ref<DataAccessibleByType<Activity[] | null>>({
    Opportunity: null,
    Lead: null,
  });
  const canGenerateProposalOnLead = ref<DataAccessibleByType<boolean>>({
    Opportunity: false,
    Lead: false,
  });
  const canViewAudience = ref<DataAccessibleByType<boolean>>({
    Opportunity: false,
    Lead: false,
  });
  const activeFilters = ref<DataAccessibleByType<OpportunityFilter[]>>({
    Opportunity: [],
    Lead: [],
  });
  const lastGlobalSearchQuery = ref<DataAccessibleByType<string>>({ Opportunity: '', Lead: '' });
  const usersWithOpportunities = ref<DataAccessibleByType<User[]>>({
    Opportunity: [],
    Lead: [],
  });

  const currentlySelectedOwnersForFilter = ref<DataAccessibleByType<User[]>>({
    Opportunity: [],
    Lead: [],
  });

  const currentlySelectedOfficesForProjections = ref<Office[]>([]);
  const currentlySelectedUsersForProjections = ref<OptionsUser[]>([]);

  const selectedVisibleLanes = ref<DataAccessibleByType<Swimlane[]>>({
    Opportunity: [],
    Lead: [],
  });

  // Variables prefixed with _ are "private" variables, and not intended to be directly manipulated outside of this file
  const _opportunities = ref<DataAccessibleByType<OpportunityBase[]>>({
    Opportunity: [],
    Lead: [],
  });

  // Define default filters for each type
  const defaultFilters: FilterStoreState['defaultFilters'] = {
    Lead: { ...leadDefaultFilters },
    Opportunity: { ...opportunityDefaultFilters },
  };

  // Initialize filter states
  const filters: FilterStoreState['filters'] = reactive({
    Lead: {
      isVisible: true,
      currentFilters: { ...defaultFilters.Lead },
      previousFilters: undefined,
    },
    Opportunity: {
      isVisible: true,
      currentFilters: { ...defaultFilters.Opportunity },
      previousFilters: undefined,
    },
  });

  // Function to reset filters to default
  const resetFilters = (type: OpportunityType) => {
    filters[type].currentFilters = { ...defaultFilters[type] };
  };

  const isFilterChanged = (type: OpportunityType): boolean => JSON.stringify(filters[type].currentFilters) !== JSON.stringify(defaultFilters[type]);

  // Function to hide filters
  const hideTableFilters = (type: OpportunityType) => {
    const filterState = filters[type];
    filterState.isVisible = false;

    const activeFilter = JSON.stringify(filterState.currentFilters);
    const defaultFilter = JSON.stringify(defaultFilters[type]);

    if (activeFilter !== defaultFilter) {
      filterState.previousFilters = { ...filterState.currentFilters };
    }

    // Instead of setting to undefined, reset to default filters to maintain DataTable integrity
    filterState.currentFilters = { ...defaultFilters[type] };
  };

  // Function to show filters
  const showTableFilters = (type: OpportunityType) => {
    const filterState = filters[type];
    filterState.isVisible = true;

    if (filterState.previousFilters) {
      filterState.currentFilters = { ...filterState.previousFilters };
      filterState.previousFilters = undefined;
    } else {
      resetFilters(type);
    }
  };

  // Start of Unique Column Configuration logic for shared LeadsTable
  const { getItem } = useLocalStorage();
  const storagePrefix = {
    Opportunity: 'crm:opportunity',
    Lead: 'crm:lead',
  };

  const tableRefs =ref({
    Opportunity: null,
    Lead: null,
  });

  // This list is required to keep in sync with the columns in the table
  const defaultTableColumns = ref({
    Opportunity:   [
      { field: 'id', header: 'Id', visible: false },
      { field: 'name', header: 'Title', visible: true },
      { field: 'company', header: 'Account', visible: true },
      { field: 'accountMarkets', header: 'Account Markets', visible: false },
      { field: 'primaryContact', header: 'Primary Contact', visible: false },
      { field: 'owner', header: 'Owner', visible: true },
      { field: 'status', header: 'Status', visible: true },
      { field: 'anticipatedStart', header: 'Anticipated Start', visible: true },
      { field: 'dueDate', header: 'Due Date', visible: false },
      { field: 'rateFormatted', header: 'Rate', visible: false },
      { field: 'interval', header: 'Interval', visible: false },
      { field: 'periods', header: 'Period', visible: false },
      { field: 'temperature', header: 'Temperature', visible: true },
      { field: 'ci', header: 'Confidence', visible: true },
      { field: 'office.office', header: 'Office', visible: false },
      { field: 'lastUpdatedDate', header: 'Last Updated', visible: false },
    ],
    Lead:   [
      { field: 'id', header: 'Id', visible: false },
      { field: 'name', header: 'Title', visible: true },
      { field: 'primaryContact', header: 'Primary Contact', visible: false },
      { field: 'company', header: 'Account', visible: true },
      { field: 'accountMarkets', header: 'Account Markets', visible: false },
      { field: 'owner', header: 'Owner', visible: true },
      { field: 'status', header: 'Status', visible: true },
      { field: 'temperature', header: 'Temperature', visible: true },
      { field: 'valueFormatted', header: 'Value', visible: false },
      { field: 'note', header: 'Note', visible: false },
      { field: 'lastUpdatedDate', header: 'Last Updated', visible: false },
    ],
  });

  const defaultVisibleColumns = computed(() => {
    const result = {};
    for (const [entityType, columns] of Object.entries(defaultTableColumns.value)) {
      result[entityType] = columns
        .filter(column => column.visible)
        .map(column => column.field);
    }
    return result;
  });

  const getVisibleColumns = () => {
    const result = {};
    for (const [entityType, prefix] of Object.entries(storagePrefix)) {
      const key = `${prefix}:visible-columns`;
      const storedColumns = getItem(key);
      result[entityType] = storedColumns ?? defaultVisibleColumns.value[entityType];
    }
    return result;
  };

  const visibleColumns = ref(getVisibleColumns());

  const getTableColumns = () => {
    const result = {};
    for (const [entityType, prefix] of Object.entries(storagePrefix)) {
      const key = `${prefix}:column-order`;
      const storedColumns = getItem<Column[]>(key);

      if (storedColumns) {
        const missingColumns = defaultTableColumns.value[entityType].filter(col =>
          !storedColumns.some(saved => saved.field === col.field),
        );

        if (missingColumns.length > 0) {
          const visibleNewFields = missingColumns
            .filter(col => col.visible)
            .map(col => col.field);

          visibleColumns.value[entityType] = [...getVisibleColumns()[entityType], ...visibleNewFields];
        }

        result[entityType] = [...storedColumns, ...missingColumns];

      } else {
        result[entityType] = defaultTableColumns.value[entityType];
      }

    }
    return result;
  };

  const tableColumns = ref(getTableColumns());
  // End of Unique Column Configuration logic for shared LeadsTable

  const protectMasterList = ref(true);
  const hasProjectionsManagerView = ref(false);

  const campaignContacts = ref<AssociationContact[]>();
  const campaignAccounts = ref<AssociationAccount[]>();

  const currentUser = ref<OptionsUser>();
  const showOpportunityCreationModal = ref(false);
  const createOpportunityContext = ref<DetailEditorContext>();

  const createOpportunityDisplayType = computed<OpportunityType>(() => {
    if (createOpportunityContext.value === 'create-lead') return 'Lead';
    return 'Opportunity';
  });

  const isFullyLoaded = computed(() => stateReady.value['Opportunity'] && stateReady.value['Lead']);

  const isDetailLoading = computed<DataAccessibleByType<boolean>>(() => ({
    Opportunity: selectedOpportunity.value['Opportunity'] === null,
    Lead: selectedOpportunity.value['Lead'] === null,
  }));

  const applyFilters = (type: OpportunityType): OpportunityBase[] => activeFilters.value[type].reduce(
    (filtered, filter) => filtered.filter(filter.predicate),
    _opportunities.value[type],
  );

  const opportunities = computed<DataAccessibleByType<OpportunityBase[]>>(() => {
    let filteredOpportunities: OpportunityBase[];
    let filteredLeads: OpportunityBase[];

    const anyOpportunityFilters = activeFilters.value['Opportunity'].length !== 0;
    const anyLeadFilters = activeFilters.value['Lead'].length !== 0;

    if (anyOpportunityFilters) {
      filteredOpportunities = applyFilters('Opportunity');
    } else {
      filteredOpportunities = _opportunities.value['Opportunity'];
    }

    if (anyLeadFilters) {
      filteredLeads = applyFilters('Lead');
    } else {
      filteredLeads = _opportunities.value['Lead'];
    }

    return {
      Opportunity: filteredOpportunities,
      Lead: filteredLeads,
    };
  });

  const visibleSwimlanes = computed<DataAccessibleByType<Swimlane[]>>(() => ({
    Opportunity: swimlanes.value['Opportunity']
      .filter(lane => selectedVisibleLanes.value['Opportunity']
        .some(l => lane.id === l.id))
      .sort(swimlaneSortCb),
    Lead: swimlanes.value['Lead']
      .filter(lane => selectedVisibleLanes.value['Lead']
        .some(l => lane.id === l.id))
      .sort(swimlaneSortCb),
  }));

  const totalOpportunityValue = computed<DataAccessibleByType<number>>(() => ({
    Opportunity: opportunities.value['Opportunity']
      .filter(opportunity => opportunity.status !== 'Lost')?.reduce((acc, current) => acc + current.value, 0),
    Lead: opportunities.value['Lead']
      .filter(opportunity => opportunity.status !== 'Lost')?.reduce((acc, current) => acc + current.value, 0),
  }));

  const areOpportunitiesHiddenByFilter = (type: OpportunityType, laneId: number) => {
    if (activeFilters.value[type].length === 0) return false;

    const hasAnyUnfiltered = _opportunities.value[type].some(opportunity => opportunity.swimlaneId === laneId);
    const hasAnyFiltered = opportunities.value[type].some(opportunity => opportunity.swimlaneId === laneId);

    return !hasAnyFiltered && hasAnyUnfiltered;
  };

  const setOpportunities = (type: OpportunityType, newOpportunities: OpportunityBase[]) => {
    if (_opportunities.value[type].length === 0 || !protectMasterList.value) {
      _opportunities.value[type] = newOpportunities;
    } else {
      console.warn('Discarded attempt to reset master list of opportunities after state has already been initialized. If you are attempting to filter the data, add your filter to activeFilters instead, and the data will automatically update');
    }
  };

  const addOpportunity = (opportunity: OpportunityBase, highlight: boolean = true) => {
    if (!opportunity.type) {
      throw new Error('Opportunity must have a type');
    }

    if (highlight) {
      opportunity.justAddedToUi = true;
      setTimeout(() => {
        const opp = _opportunities.value[opportunity.type].find(o => o.id === opportunity.id);
        if (opp) {
          opp.justAddedToUi = false;
        }
      }, 10000);
    }

    _opportunities.value[opportunity.type].unshift(opportunity);
  };

  const removeOpportunity = (type: OpportunityType, id: number) => {
    const idx = _opportunities.value[type].findIndex(opportunity => opportunity.id === id);
    if (idx === -1) return;
    _opportunities.value[type].splice(idx, 1);
  };

  const updateOpportunity = (type: OpportunityType, opportunity: OpportunityBase) => {
    const idx = _opportunities.value[type].findIndex(o => o.id === opportunity.id);
    if (idx === -1) return;
    _opportunities.value[type][idx] = opportunity;
  };

  const swimlaneSortCb = (a: Swimlane, b: Swimlane) => {
    const aOrder = parseInt(a.order, 10);
    const bOrder = parseInt(b.order, 10);
    if (isNaN(aOrder) || isNaN(bOrder)) {
      throw new Error('Invalid value for lane sort field');
    }

    if (aOrder < bOrder) return -1;
    if (aOrder > bOrder) return 1;
    return 0;
  };

  const opportunityEndpoint = useEndpoint<PayloadBase<OpportunityBase>>({
    url: '/crm/opportunities',
    type: 'POST',
    successField: 'data',
    onSuccess: response => {
      _opportunities.value['Lead'].push(response.data.opportunity);
    },
    toast: { showOnSuccess: false },
  });


  const newOpportunityData = ref<PayloadBase<OpportunityBase>>();

  const accountEndpoint = useEndpoint<CreateAccountResponse>({
    url: '/crm/accounts',
    type: 'POST',
    successField: 'data',
    onSuccess: response => {
      const opportunity = newOpportunityData.value;
      opportunity.data.opportunity.accountId = response.data.account.c2_id;
      opportunityEndpoint.makeRequest(opportunity);
    },
    toast: { showOnSuccess: false },
  });

  const onLeadAddedFromMap = (e: CustomEvent) => {
    const accountData = { data: { account: e.detail.account }};

    const opportunityData = { data: { opportunity: e.detail.opportunity }};
    newOpportunityData.value = opportunityData;

    accountEndpoint.makeRequest(accountData);
  };

  onMounted(() => {
    window.addEventListener('lead-added-from-map', onLeadAddedFromMap);
  });

  onUnmounted(() => window.removeEventListener('lead-added-from-map', onLeadAddedFromMap));

  return {
    activeFilters,
    addOpportunity,
    areOpportunitiesHiddenByFilter,
    campaignAccounts,
    campaignContacts,
    canGenerateProposalOnLead,
    canViewAudience,
    createOpportunityContext,
    createOpportunityDisplayType,
    currentlySelectedOfficesForProjections,
    currentlySelectedOwnersForFilter,
    currentlySelectedUsersForProjections,
    currentState,
    currentUser,
    currentView,
    defaultTableColumns,
    defaultVisibleColumns,
    filters,
    hasProjectionsManagerView,
    hideTableFilters,
    isDetailLoading,
    isFilterChanged,
    isFullyLoaded,
    lastGlobalSearchQuery,
    opportunities,
    opportunityActivities,
    options,
    protectMasterList,
    removeOpportunity,
    resetFilters,
    selectedCampaignId,
    selectedOpportunity,
    selectedVisibleLanes,
    setOpportunities,
    shouldShowDetail,
    showOpportunityCreationModal,
    showTableFilters,
    stateReady,
    storagePrefix,
    swimlanes,
    tableColumns,
    tableRefs,
    totalOpportunityValue,
    updateOpportunity,
    usersWithOpportunities,
    viewFormat,
    visibleColumns,
    visibleSwimlanes,
  };
});
