import { z } from 'zod';

export const optionalNumber = (allowNegative: boolean = false) => z.number().nullable().optional().superRefine((val, ctx) => {
  if (val === undefined || val === null || (val as unknown as string) === '') return;

  const num = typeof val === 'number' || typeof val === 'string' ? Number(val) : NaN;

  if (isNaN(num)) {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      message: 'Value must be a number',
    });

    return;
  }

  if (num < 0 && !allowNegative) {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      message: 'Value must be positive',
    });
  }
});
