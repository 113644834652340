import { z } from 'zod';
import { SchemaErrors } from './SchemaErrors';

export const office = z.object({
  id: z.number(),
  office: z.string(),
});

export type OfficeSchema = z.infer<typeof office>;
export type OfficeValidationErrors = SchemaErrors<OfficeSchema>;
