import { z } from 'zod';
import { useAddressableDetailSchema } from './useAddressableDetailSchema.ts';

const {
  addressSchema, phoneNumberSchema, webPageSchema, emailSchema,
} = useAddressableDetailSchema();

const contactDetailSchema = z.object({
  id: z.number().positive(),
  salutation: z.string(),
  firstName: z.string().nonempty({ message: 'First Name must contain at least one character(s)' }),
  middleName: z.string(),
  lastName: z.string(),
  suffix: z.string(),
  nickname: z.string(),
  jobTitle: z.string(),
  companyName: z.string(),
  addresses: z.array(addressSchema),
  phoneNumbers: z.array(phoneNumberSchema),
  webPages: z.array(webPageSchema),
  emails: z.array(emailSchema),
});

export type ContactDetailSchema = z.infer<typeof contactDetailSchema>;


export type ContactDetailErrors = {
  [K in keyof ContactDetailSchema]?: string;
}

export const useContactDetailSchema = () => ({
  contactDetailSchema,
});
