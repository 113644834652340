import { z } from 'zod';
import { SchemaErrors } from './SchemaErrors';
import { owner } from './Owner';
import { temperature } from './Temperature';
import { opportunityStatus } from './OpportunityStatus';
import { optionalNumber } from './OptionalNumber';

export const leadDetail = z.object({
  id: optionalNumber(),
  name: z.string().nonempty('Name is a required field'),
  owner,
  status: opportunityStatus,
  temperature: temperature.optional(),
  type: z.union([z.literal('Lead'), z.literal('Opportunity')]),
  value: optionalNumber(),
});

export type LeadDetailSchema = z.infer<typeof leadDetail>;
export type LeadDetailValidationErrors = SchemaErrors<LeadDetailSchema>;
