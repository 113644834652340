import { z } from 'zod';
import type { SchemaErrors } from './SchemaErrors';

export const temperature = z.object({
  id: z.number(),
  temperature: z.string(),
  color: z.string(),
});

export type TemperatureSchema = z.infer<typeof temperature>;
export type TemperatureValidationErrors = SchemaErrors<TemperatureSchema>;
