import { z } from 'zod';
import type { SchemaErrors } from './SchemaErrors';

const opportunityTypeEnum = z.enum(['Lead', 'Opportunity']);

export const opportunityStatus = z.object({
  id: z.number(),
  status: z.string(),
  order: z.string().optional(),
  state: z.string().optional(),
  type: opportunityTypeEnum.optional().default('Opportunity'),
});

export type OpportunityStatusSchema = z.infer<typeof opportunityStatus>;
export type OpportunityStatusValidationErrors = SchemaErrors<OpportunityStatusSchema>;
